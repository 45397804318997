export default {
    name: 'LoginComponent',
    data(){
        return {
            email_id : '',
            password : '',
            show_password : true,
            email_pwd_validation:''
        }
    },
    created(){

        if(sessionStorage.getItem('organization_details')){
            this.$router.push('/workbench');
        }else{
            let path = "/"
            if (this.$route.path !== path) {
                this.$router.push(path)
            }
        }

    },
    methods:{
        async login_submit(){
            if(this.email_id != "" && this.password == "pro123"){
                try{
                   await this.$store.dispatch('getLoginDetails', [this.$store.state.api,{email:this.email_id}]);
                    if(this.$store.state.login_details_obj.member_details.is_organiser == true){
                        let organization_details = {
                            "org_id":this.$store.state.login_details_obj.member_details.org_id,
                            "org_name" : this.$store.state.login_details_obj.org_name,
                            "organiser_id":this.$store.state.login_details_obj.member_details._id,
                            "full_name": this.$store.state.login_details_obj.member_details.full_name,
                            "email" :  this.$store.state.login_details_obj.member_details.email,
                            "is_organiser": this.$store.state.login_details_obj.member_details.is_organiser
                        }

                        sessionStorage.setItem("organization_details",JSON.stringify(organization_details));

                        this.$store.state.org_id = this.$store.state.login_details_obj.member_details.org_id ;

                        this.$store.state.org_name = this.$store.state.login_details_obj.org_name;

                        this.$store.state.organiser_id = this.$store.state.login_details_obj.member_details._id;

                        this.$router.push('/workbench');
                    }else{
                        this.email_pwd_validation = "You cannot login as participant. Please contact admin";
                    }
                }catch(e){
                    this.email_pwd_validation = "Member with the given email does not exist";
                }
            }else{
                this.email_pwd_validation = "Username/Password Incorrect"
            }

        }
    }
}
  
