/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import LoginBgComponent from '@/components/loginBg/login_bg_component.vue'
import LoginComponent from '@/components/Login/login_component.vue'

export default {
  name: 'Login',
  components: {
    LoginBgComponent,
    LoginComponent
  }
}

